import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";

import "slick-carousel/slick/slick.min";

declare const $: any;

export class Slider extends AbstractComponent
{
    public static selector: string = 'slider';

    public constructor(componentElement: any, DI)
    {
        super(componentElement, DI);
    }

    public init()
    {
	    this.getComponentElement().slick();
    }
}

new ComponentLoader(Slider);
